import React from 'react';
import styled from 'styled-components';

export default function Header() {
  return (
    <Container>
      <h3 className='accessibility_header-title'>Accessibility</h3>
    </Container>
  );
}

const Container = styled.div`
  background-color: #247e46;
  h3 {
    color: white;
    padding: 20px 30px;
    margin: 0rem;
    text-align: center;
    font-family: "AvenirLTStd-Roman", sans-serif;
    a {
      text-decoration: none;
      color: white;
    }
  }
`;
