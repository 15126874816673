import React, { useContext } from "react";
import CloseIcon from "./assets/CloseIcon";
import Header from "./Header";
import StyleSettings from "./StyleSettings.js";
import Footer from "./Footer.js";
import { store } from "./Context/Store";
import styled from "styled-components";
import WheelChair from "./assets/wheelchair.png";

export default function Widget() {
  const { globalState, dispatch } = useContext(store);

  return (
    <Container>
      <InnerContainer>
        <Button onClick={() => dispatch({ type: "OPEN_WIDGET" })}>
          {/* Accessibility Icon */}
          <img
            src={WheelChair}
            alt="Wheel Chair"
            className="accessibility_primary-btn"
            style={{ height: "30px", width: "30px" }}
          />
        </Button>
        {globalState.widgetOpen && (
          <WidgetContainer>
            <CloseButton onClick={() => dispatch({ type: "CLOSE_WIDGET" })} />
            <Header />
            <WidgetBox>
              <StyleSettings />
            </WidgetBox>
            <Footer />
          </WidgetContainer>
        )}
      </InnerContainer>
    </Container>
  );
}

const Container = styled.div`
  font-family: "AvenirLTStd-Roman", sans-serif;
  user-select: none;
`;

const InnerContainer = styled.div`
  font-family: "AvenirLTStd-Roman", sans-serif;
`;

const Button = styled.div`
  position: fixed;
  bottom: 20px;
  left: 20px;
  background-color:rgba(36, 126, 71, 0.79);
  color: white;
  padding: 8px;
  z-index: 9998;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.1);
    background-color: #247e46;
  }

  img {
    max-width: 60px;
  }
`;

const WidgetContainer = styled.div`
  position: fixed;
  bottom: 10px;
  left: 10px;
  width: calc(100% - 25px);
  max-height: 450px;
  max-width: 380px;
  min-height: 300px;
  min-width: 280px;
  z-index: 9999;
  overflow: hidden;
  border-radius: 10px;
`;

const CloseButton = styled(CloseIcon)`
  position: absolute;
  top: 0.25rem;
  right: 0.25rem;
  background-color: white;
  color: black;
  border-radius: 100px;
  height: 25px;
  width: 25px;
  cursor: pointer;
  /* transform: translate(-50%, -50%); */
`;

const WidgetBox = styled.div`
  background-color: #eff1f5;
  width: 100%;
  height: 400px;
  overflow-y: scroll;
  overflow-x: hidden;
  color: black;
  ::-webkit-scrollbar {
    width: 10px;
  }
  ::-webkit-scrollbar-track {
    background: #eff1f5;
    border-radius: 0px 10px 10px 0px;
  }
  ::-webkit-scrollbar-thumb {
    background: #888;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;
