import React from "react";
import { Link } from "react-router-dom";
import CompanyLogo from "../assets/logo.webp";
import ScrollArrow from "./scroll-arrow";
import Accessibility from "./AccessibilityWidget/components/AccessibilityWidget";
import "./NotFound.css";
import BadImg from "../assets/bad-t.png";

export default function NotFound() {
  return (
    <div>
      <div style={{ width: "100%", position: "relative" }}>
        <Link to="/" className="link">
          <img
            src={CompanyLogo}
            alt="Company Logo"
            className="not-found-page-company-logo"
          />
        </Link>
        <ScrollArrow />
        <Accessibility />
      </div>

      <div style={{ paddingTop: "100px" }}>
        <center>
          <div className="not-found-testimonial-content">
            <div className="not-found-testimonial-image">
              <img src={BadImg} alt="" />
            </div>
            <div className="not-found-testimonial-text">
              <h1
                style={{
                  color: "#000",
                  marginBottom: "20px",
                  fontSize: "40px",
                  fontFamily: '"GT America Trial Cm Bd", sans-serif',
                }}
                className="not-found-heading"
              >
                Oops! All the pages ran away.
              </h1>
              <p
                style={{
                  color: "#000",
                  fontSize: "30px",
                  fontFamily: '"GT America Trial Cn Lt", sans-serif',
                }}
                className="not-found-text"
              >
                Please visit our home page or sitemap to find the information
                you are looking for.
              </p>
              <br />
              <div className="not-found-button-container">
                <a href="/" className="not-found-button">
                  Home Page
                </a>
                <a href="/sitemap/" className="not-found-button">
                  Sitemap
                </a>
              </div>
            </div>
          </div>
        </center>
      </div>
    </div>
  );
}
