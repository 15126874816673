import React, { useContext } from "react";
import styled from "styled-components";
import { store } from "./Context/Store";

export default function Footer({ setStyleSettings }) {
  const { dispatch } = useContext(store);
  return (
    <Container>
      <Button
        id="accessibility_reset-btn"
        onClick={() =>
          dispatch({
            type: "RESET_SETTINGS",
          })
        }
      >
        Reset
      </Button>
    </Container>
  );
}

const Container = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  background-color: #247e46;
  padding: 0.25rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Button = styled.button`
  border-radius: 50px;
  background-color: white;
  color: black;
  border: none;
  padding: 0.5rem 0.75rem;
  font-size: 13px;
  cursor: pointer;
  font-family: "AvenirLTStd-Roman", sans-serif;
`;
