const accessibilitySettings = {
  widgetOpen: false,
  // == text ==
  fontColor: false,
  fontSizeAdjustment: 0,
  lineHeight: 0,
  letterSpacing: 0,
  textBold: false,
  textCase: "initial",
  textAlignment: "initial",
  textItalic: false,
  // == titles ==
  titleColor: false,
  titleBackgroundColor: null,
  highlightTitles: false,
  // == other settings ==
  highlightLinks: false,
  hideImages: false,
  monochrome: false,
  highContrast: false,
  lowContrast: false,
  blueLightFilter: false,
  visualImpairment: false,
};

export default accessibilitySettings;
