import React from "react";

const MinusIcon = () => (
  <svg
    stroke="currentColor"
    fill="black"
    strokeWidth="0"
    viewBox="0 0 1024 1024"
    height="1em"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    id="accessibility_conter-minus-btn"
  >
    <path d="M872 474H152c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h720c4.4 0 8-3.6 8-8v-60c0-4.4-3.6-8-8-8z"></path>
  </svg>
);

export default MinusIcon;
