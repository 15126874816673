import React, { useContext } from 'react';
// import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import { store } from '../Context/Store';
import styled from 'styled-components';
import SettingsBox from './SettingsBox';

export default function TextCase() {
  const { globalState, dispatch } = useContext(store);

  const options = ['initial', 'uppercase', 'lowercase', 'capitalize'];

  return (
    <SettingsBox title={'Text Case'}>
      <Container id='accessibility_text-case-container'>
        {/* <Dropdown
          options={options}
          onChange={(e) => dispatch({ type: 'SET_TEXT_CASE', data: e.value })}
          value={globalState.textCase}
          placeholder='Select an option'
        /> */}
        <select 
          id="accessibility_text-case-dropdown" 
          onChange={(e) => dispatch({ type: 'SET_TEXT_CASE', data: e.target.value })}
          value={globalState.textCase}
        >
          {options.map((item)=>{
            return(
              <option className='accessibility_text-case-dropdown' value={item}>{item}</option>
            )
          })}
        </select>
      </Container>
    </SettingsBox>
  );
}

const Container = styled.div`
  #accessibility_text-case-dropdown {
    background-color: transparent;
    border-color: #eff1f5;
    color: #353535 !important;
    font-family: "AvenirLTStd-Roman", sans-serif;
    width: fit-content;
    font-size: 14px;
    padding: 5px;
    text-transform: capitalize !important;

    .accessibility_text-case-dropdown {
      text-transform: capitalize !important;
    }
  }
`;
