import React, { useContext } from 'react';
import Toggle from './Toggle';
import { store } from '../Context/Store';
// import styled from 'styled-components';
import SettingsBox from './SettingsBox';

export default function BlueLightFilter() {
  const { globalState, dispatch } = useContext(store);

  return (
    <SettingsBox title={'Blue Light Filter'}>
      <Toggle
        onChange={() =>
          dispatch({
            type: globalState.blueLightFilter
              ? 'UNSET_BLUE_LIGHT_FILTER'
              : 'SET_BLUE_LIGHT_FILTER',
          })
        }
        checked={globalState.blueLightFilter}
      />
    </SettingsBox>
  );
}

// const Container = styled.div`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   padding: 0.5rem 1rem;
//   h5 {
//     margin-right: 0.5rem;
//     color: lightgrey;
//     font-size: 0.7rem;
//   }
// `;
