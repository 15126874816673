import React from 'react';
import styled from 'styled-components';

export default function SectionTitle({ children }) {
  return <Container id='accessibility_section-title'>{children}</Container>;
}

const Container = styled.h4`
  width: 100%;
  border-bottom: 1px solid #247e46;
  padding: 5px 5px 5px 0;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 15px;
  color: #247e46;
  letter-spacing: 1px;
  margin: 0px 0px 16px;
  font-family: "AvenirLTStd-Roman", sans-serif;
`;
