import React from "react";

const CloseIcon = (props) => (
  <svg
    stroke="currentColor"
    fill="black"
    strokeWidth="0"
    viewBox="0 0 16 16"
    height="1em"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    id="accessibility_close-btn"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 8.707l3.646 3.647.708-.707L8.707 8l3.647-3.646-.707-.708L8 7.293 4.354 3.646l-.707.708L7.293 8l-3.646 3.646.707.708L8 8.707z"
    ></path>
  </svg>
);

export default CloseIcon;
