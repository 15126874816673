const CreateStyles = (globalState) => {
  const {
    // widgetOpen,
    fontColor,
    fontSizeAdjustment,
    lineHeight,
    titleColor,
    textItalic,
    letterSpacing,
    textBold,
    textCase,
    titleBackgroundColor,
    highlightTitles,
    highlightLinks,
    hideImages,
    textAlignment,
    monochrome,
    highContrast,
    lowContrast,
    blueLightFilter,
    visualImpairment,
  } = globalState;

  return `
    html {
        ${monochrome ? `filter:grayscale(1) !important;` : ""}

        ${blueLightFilter ? `filter:sepia(80%) !important;` : ""}
        
        ${visualImpairment ? `filter:invert(100%) !important;` : ""}
    }

    *{
        ${fontColor ? `color:${fontColor} !important;` : ""}

        ${textBold ? `font-weight:bold !important;` : ""}
        
        ${
          textCase !== "initial" ? `text-transform:${textCase} !important;` : ""
        }
        ${textItalic ? `font-style:italic !important;` : ""}

        ${hideImages ? `background-image:none !important;` : ""}

    }

    img {
        ${hideImages ? `opacity:0 !important;` : ""}
        ${highContrast ? `filter: contrast(2) !important;` : ""}
    }

    a {
        ${highlightLinks ? `border:solid 2px black !important; ` : ""}

        ${
          textAlignment !== "initial"
            ? `text-align:${textAlignment} !important;`
            : ""
        }
    }

    h1,h2,h3,h4,h5,h6 {
        ${highlightTitles ? `border:solid 2px black !important;` : ""}
  
        ${titleColor ? `color:${titleColor} !important;` : ""}

        ${
          titleBackgroundColor
            ? `background-color:${titleBackgroundColor} !important;`
            : ""
        }

     
        ${
          fontSizeAdjustment
            ? `zoom:${1 + fontSizeAdjustment / 101} !important;`
            : ""
        }

        ${
          lineHeight ? `line-height:${1.25 + lineHeight / 101} !important;` : ""
        }

        ${
          letterSpacing
            ? `letter-spacing:${letterSpacing / 10}px !important;`
            : ""
        }
        ${highContrast ? `filter: contrast(2) !important;` : ""}
        ${lowContrast ? `filter: contrast(0.5) !important;` : ""}
        
        ${
          textAlignment !== "initial"
            ? `text-align:${textAlignment} !important;`
            : ""
        }
    }

    p, button, li, ol {
        ${
          fontSizeAdjustment
            ? `zoom:${1 + fontSizeAdjustment / 101} !important;`
            : ""
        }

        ${lineHeight ? `line-height:${1 + lineHeight / 101} !important;` : ""}
        

        ${
          letterSpacing
            ? `letter-spacing:${letterSpacing / 10}px !important;`
            : ""
        }
        ${highContrast ? `filter: contrast(2) !important;` : ""}
        ${lowContrast ? `filter: contrast(0.5) !important;` : ""}

        ${
          textAlignment !== "initial"
            ? `text-align:${textAlignment} !important;`
            : ""
        }

    }

    #accessibility_reset-btn, 
    #accessibility_close-btn{
      color: black !important;
      zoom: 0 !important;
      line-height: 1 !important;
      letter-spacing: 0px !important;
      text-transform: initial !important;
      text-align: initial !important;
      font-style: normal !important;
      font-weight: initial !important;
      background-color: white !important;
      transition: scale 0.3s ease;
    }

    #accessibility_reset-btn:hover{
      scale: 1.1 !important;
    }

    .accessibility_font-varient-btn{
      zoom: 0 !important;
    }

    .accessibility_header-title, 
    .accessibility_header-title-a, 
    #accessibility_conter-value{
      color: white !important;
      zoom: 0 !important;
      line-height: 1 !important;
      letter-spacing: 0px !important;
      text-transform: initial !important;
      text-align: center !important;
      font-style: normal !important;
      background-color: initial !important;
      border:none !important;
    }

    #accessibility_conter-value{
      font-weight: initial !important;
      background-color: #247e46 !important;
    }

    #accessibility_section-title, 
    #accessibility_sub-section-title{
      color: #353535 !important;
      zoom: 0 !important;
      line-height: 1 !important;
      letter-spacing: 0px !important;
      text-transform: initial !important;
      text-align: initial !important;
      font-style: normal !important;
      font-weight: bold !important;
      background-color: initial !important;
      border:none !important;
    }
    
    #accessibility_section-title{
      border-bottom: 1px solid #353535 !important;
    }

    #accessibility_conter-minus-btn:hover, #accessibility_conter-plus-btn:hover{
      fill: #247e46 !important;
    }

    #accessibility_text-case-dropdown, 
    .accessibility_text-case-dropdown{
      color: #353535 !important;
      zoom: 0 !important;
      line-height: 1 !important;
      letter-spacing: 0px !important;
      text-transform: initial !important;
      text-align: left !important;
      font-style: normal !important;
      font-weight: initial !important;
      background-color: initial !important;
    }
    
    #accessibility_text-case-container{
      text-align: left !important;
    }

    .accessibility_primary-btn{
      opacity: 1 !important;
    }
    `;
};

export default CreateStyles;
