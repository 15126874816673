import React from "react";
import styled from "styled-components";

export default function SubSectionTitle({ children }) {
  return <Container id="accessibility_sub-section-title">{children}</Container>;
}

const Container = styled.h5`
  width: fit-content;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 15px;
  letter-spacing: 1px;
  color: #247e46;
  margin: 0px 5px 8px;
  box-sizing: border-box;
  font-family: "AvenirLTStd-Roman", sans-serif;
`;
